import { range, map, findIndex, filter, every } from 'lodash';

export default {
  config: (state) => state.config,
  activeGameLayout: (state) => state.gameLayout,
  isAudioOn: (state) => state.isAudioOn,
  bingoNumbers: (state, getters) => {
    const numbers = range(state.bettingRules.minBingoNumber, state.bettingRules.maxBingoNumber + 1);
    return map(numbers, (number) => {
      const color = getters.getNumberColor(number);
      return {
        hex: color,
        value: number,
        isSelected: findIndex(getters.selectedBingoNumbers, (n) => n.number === number) > -1,
      };
    });
  },
  getNumberColor: (state) => (number) => {
    const colors = state.bettingRules.bingoColors;
    return colors[(number - 1) % colors.length];
  },
  firstBallColor: (state) => state.config.bets[22],
  bettingData: (state) => state.bettingData,
  specialBetEnabled: (state) => (id) => {
    const specialBet = state.specialBets.find((bet) => bet.id === id);
    return specialBet ? specialBet.value : false;
  },
  getSpecialBet: (state) => (id) => state.specialBets.find((bet) => bet.id === id),
  specialBetsEnabled: (state) => {
    return !!state.specialBets.filter((bet) => bet.value).length;
  },
  regulatoryMessageText: (state) => state.config.ui.config.regulatoryMessage,
  resultsData: (state) => state.resultsData,
  isIsonis: (state) => state.isonisList.indexOf(state.config.taxAuthority) > -1,
  player: (state) => state.player,
  isBoostActive: (state) => state.config.ui.config?.boost?.enabled || false,
  // Getters needed by the betslip
  roundId: (state) => state.roundId,
  isBettingDisabled: (state) => state.isBettingDisabled,
  activeComponent: (state) => state.activeComponent,
  layout: (state) => state.layout,
  isTerminal: (state) => state.isTerminal,
  terminalUserData: (state) => state.terminalUserData,
  device: (state) => state.device,
  betshop: (state) => state.betshop,
  companyData: (state) => state.companyData,
  account: (state) => state.account,
  mode: (state) => state.config.ui.config.mode || 'fullWeb',
  ticketDetailsData: (state) => state.ticketDetailsData,
  isLoaded: (state) => state.isLoaded,
  ticketHistoryTerminal: (state) => state.ticketHistoryTerminal,
  arrowsVisibility: (state) => state.arrowsVisibility,
  userIsOperator: (state) =>
    state.terminalUserData.userType === 'OPERATOR' || state.terminalUserData.type === 'OPERATOR',
  getTranslation: (state) => (key) => state.translations.translations[key] || key,
  nextRoundTime: (state) => state.nextRoundTime,
  currentEventId: (state) => state.currentEventId,
  roundInProgress: (state) => state.roundInProgress,
  ticketToRebet: (state) => state.ticketToRebet,
  timer: (state) => state.timer,
  systemBetsDisabled: (state) => {
    const { bets } = state.config;
    const systemBets = filter(bets, (bet) => bet.betType === 'System');
    return every(systemBets, ['value', false]);
  },
  isExpandedLayout: (state) => state.gameLayout === 'Expanded',
  isFreeBetMode: () => null,
};
